import discountAPI from '@/APIs/boDiscountAPI'

const state = {
  discounts: [],
};

const getters = {

};

const actions = {
  /**
   * Llama al servicio de listado de aplicaciones
   */
  listDiscounts({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      discountAPI.get(url, params)
        .then((response) => {
          //console.log("listDiscounts");
          //console.log(response.data);
          commit('SET_DISCOUNTS', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // New
  newDiscount({ commit }, item) {
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      discountAPI.post(``, item )
        .then((response) => {
          commit('CREATE_DISCOUNT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Update
  updateStatus({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      discountAPI.put(`/updatediscount/${item.id}/${item.status}`, item )
        .then((response) => {
          commit('UPDATE_DISCOUNT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Update
  generateDiscount({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      discountAPI.put(`/generatediscount/${item.id}`, item )
        .then((response) => {
          commit('UPDATE_DISCOUNT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
};

const mutations = {

  SET_DISCOUNTS(state, discounts) {
    state.discounts = discounts
  },
  CREATE_DISCOUNT(state, discount) {
    state.discount = discount;
  },
  UPDATE_DISCOUNT(state, discount) {
    const discountIndex = state.discounts.findIndex((p) => p.id == discount.id)
    Object.assign(state.discounts[discountIndex], discount)
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
