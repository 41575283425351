import realEstateProjectAPI from '@/APIs/boRealEstateProjectAPI';

export default {

  imgResponse: function (identifier) {

    const url = '/uploadImg/';

      const doc = 'img_'+identifier;

    var code = url+doc;

    return code;
    //return edocAPI.get(code);
  },

  imgUpload: function (identifier, formData) {

    const url = '/uploadImg/'+identifier;
    //console.log(formData);

    let headers = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(
          realEstateProjectAPI.post(url, formData, headers)
        );
      }, 2000);
    });
  },
  uploadUnits: function (projectId, formData) {

    const url = '/'+projectId+'/units/upload';

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(
          realEstateProjectAPI.post(url, formData)
        );
      }, 2000);
    });
  },
  listProjects: function(page, size) {
    const url = '/';

    const parameters = {
      page: page,
      size: size
    }

    return realEstateProjectAPI.get(url, { params: parameters });
  },

};
