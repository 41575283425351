import axios from 'axios';

const realEstateProjectAPIURL = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BO_PRO_API;

const projectAPI = axios.create({
  baseURL: realEstateProjectAPIURL
});

export const setAuthHeader = (token) => {
  projectAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

projectAPI.interceptors.request.use((config) => {
  let authInfo = JSON.parse(localStorage.getItem('authInfo'));

  if (authInfo) {
    config.headers['Authorization'] = `Bearer ${ authInfo.access_token }`
  }

  return config;
}, error => {
  return Promise.reject(error)
});

export default projectAPI;
