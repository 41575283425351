<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "AGREGAR" : "EDITAR" }} DESCUENTO</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">


        <!---->

              <div class="vx-col md:w-full w-full mt-5" v-show="!clientNameUpdate">
                <vs-select autocomplete v-model="selectedClientId" class="w-full select-large" label="Cliente" name="project" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in clientOptions" class="w-full" />
                </vs-select>
                <span class="text-danger">{{ errors.first('unit-step.project') }}</span>
              </div>

              <div class="vx-col md:w-full w-full mt-5" v-show="!projectNameUpdate">
                <vs-select autocomplete v-model="selectedProjectId" class="w-full select-large" label="Proyecto" name="project" v-validate="'required'"
                           >
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in projectOptions" class="w-full" />
                </vs-select>
                <span class="text-danger">{{ errors.first('unit-step.project') }}</span>
              </div>
              <!-- Tipologia -->
              <div class="vx-col md:w-full w-full mt-5" v-show="!projectNameUpdate">              
                <vs-select autocomplete v-model="selectedTypology" class="w-full select-large" label="Tipología" name="typology" v-validate="'required'"
                           :disabled="!selectedProjectId" @change="selectTypology">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in typologyOptions" class="w-full" />
                </vs-select>
                <span class="text-danger">{{ errors.first('unit-step.typology') }}</span>
              </div>
              <!-- Tipo -->
              <div class="vx-col md:w-full w-full mt-5" v-show="!projectNameUpdate">
                <vs-select autocomplete v-model="selectedType" class="w-full select-large" label="Tipo" name="typology" v-validate="'required'"
                           :disabled="!selectedTypology" @change="selectType">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in orderedType" class="w-full" />
                </vs-select>
                <span class="text-danger">{{ errors.first('unit-step.type') }}</span>
              </div>
              <!-- Número de unidad -->
              <div class="vx-col md:w-full w-full mt-5" v-show="!projectNameUpdate">
                <vs-select autocomplete v-model="selectedUnit" class="w-full select-large" label="Unidad" name="number" v-validate="'required'"
                           :disabled="!selectedType">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in unitOptions" class="w-full" />
                </vs-select>
                <span class="text-danger">{{ errors.first('unit-step.unit') }}</span>
              </div>


        <div v-show="clientNameUpdate">
          <br><br>
          Cliente: {{ clientNameUpdate }}
        </div>
        <div v-show="projectNameUpdate">
          <br><br>
          Proyecto: {{ projectNameUpdate }}
        </div>
        <div v-show="unitNumberUpdate">
          <br><br>
          Unidad: {{ unitNumberUpdate }}
        </div>
        <!---->
        <!-- UNITNUMBER -->
        <!--<vs-input label="Unidad" v-model="unitNumber" class="mt-5 w-full" name="item-name" v-validate="'required'" 
        :disabled="isNew"/>-->
        <!-- PRICE -->
        <vs-input label="Valor" v-model="amount" type="number" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- STATUS -->
        <vs-select autocomplete v-model="datastatus" label="Estado" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in category_choices" />
        </vs-select>

        <!-- TYPE -->
        <vs-select autocomplete v-model="datatype" label="Tipo" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in type_choices" />
        </vs-select>

              <br>
              <br>
              <br>
              <br>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData()" v-show="!clientNameUpdate" >Guardar</vs-button>
      <!--<vs-button class="mr-6" color="danger" @click="deleteData()" @click.stop="$vs.notify({
                      title:'Eliminado',
                      text:'La unidad ha sido eliminada',
                      color:'danger'})" >Eliminar</vs-button>-->
      <br>
      <br>
      <br>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import RealEstateProjectService from '@/services/BOProjectService.js';
import ClientService from '@/services/ClientService.js';

import Vue from 'vue'
import VeeValidate from 'vee-validate'

Vue.use(VeeValidate)

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
        //console.log(val);
        if (this.data.amount!=undefined) {
          this.isNew=true;
        } else {
          this.isNew=false;
        }
      if(!val) return
        /*
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
        let { projectId } = JSON.parse(JSON.stringify(this.data))
        this.projectId = projectId
        console.log("this data");
        console.log(JSON.parse(JSON.stringify(this.data)));
      }else {
        let { unitId, projectId, unitNumber, amount, currency, description, status, type } = JSON.parse(JSON.stringify(this.data))
        this.id = unitId
        this.projectId = projectId
        this.unitNumber = unitNumber
        this.amount = amount
        this.currency = currency
        this.description = description
        this.status = status
        this.type= type
        console.log("else");
        //this.initValues()
      }*/

        let { discountId, clientId, projectId, unitNumber, amount, status, type, scope, projectName, clientName } = JSON.parse(JSON.stringify(this.data))
        this.id = discountId
        this.dataclientId = clientId
        this.dataprojectId = projectId
        this.dataunitNumber = unitNumber
        this.amount = amount
        this.datastatus = status
        this.datatype= type
        this.datascope= scope
        this.unitNumberUpdate= unitNumber
        this.projectNameUpdate= projectName
        this.clientNameUpdate= clientName
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {
      unitNumberUpdate: "",
      projectNameUpdate: "",
      clientNameUpdate: "",
      isNew: false,
      id:"",
      projectId: "",
      unitNumber: null,
      amount: null,
      currency: [],
      floor: 1,
      description: "",
      datastatus: "",
      typology: "",
      datatype: "",
      datascope: "",
      dataStatus: "",
      totalSize:"",
      terraceSize:"",
      interiorSize:"",
      rooms:1,
      bathrooms:1,
      orientation:"",
      dataName: "",
      dataImg: null,

      typologyOptionsMap: new Map(),
      typeOptionsMap: new Map(),
      unitOptionsMap: new Map(),
      projectOptions: [],
      clientOptions: [],
      selectedProjectId: '',
      selectedClientId: '',
      selectedTypology: '',
      selectedType: '',
      projectAdditional: [],
      projectAdditionalWarehouse: [],
      selectedUnitId: '',
      selectedProject: {},
      selectedUnit: {
        unitType: {
          typology: '',
          type: '',
          orientation: '',
          totalSize: '',
          interiorSize: '',
        },
        unitNumber: '',
        price: '',
      },
      selectedAdditional: {
        unitNumber: '',
        price: 0,
        description: '',
        type: ''
      },
      selectedAdditionalWarehouse: {
        unitNumber: '',
        price: '',
        description: '',
        type: ''
      },

      scope_choices: [
        {text:'Una vez',value:'ONE_CLIENT_ONE_USE'}
      ],
      category_choices: [
        {text:'Disponible',value:'AVAILABLE'},
        {text:'Nuevo',value:'NEW'}
      ],
      type_choices: [
        {text:'Porcentaje',value:'PERCENTAGE'},
        {text:'Cantidad',value:'FIXED_AMOUNT'}
      ],
      corientation_choices: [
        {text:'Norte',value:'NORTH'},
        {text:'Sur',value:'SOUTH'},
        {text:'Oriente',value:'EAST'},
        {text:'Poniente',value:'WEST'},
        {text:'NorOriente',value:'NORTH_EAST'},
        {text:'NorPoniente',value:'NORTH_WEST'},
        {text:'SurOriente',value:'SOUTH_EAST'},
        {text:'SurPoniente',value:'SOUTH_WEST'}
      ],

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  beforeMount() {
    this.listActiveProjectsByCompany();
    this.listClients();
  },
  computed: {
    typologyOptions: function() {
      return this.selectedProjectId ? this.typologyOptionsMap.get(this.selectedProjectId) : [{ text: 'No disponibles', value: 'No disponibles' }];
    },
    typeOptions: function() {
      let key = this.selectedProjectId + '-' + this.selectedTypology;
      return this.selectedTypology ? this.typeOptionsMap.get(key) : [{ text: 'No disponibles', value: 'No disponibles' }];
    },
    orderedType: function () {
      /*function compare(a, b) {
        if (a.text < b.text)
          return -1;
        if (a.text > b.text)
          return 1;
        return 0;
      }*/
      //console.log(compare);
      //return this.typeOptions.sort(compare);
      return this.typeOptions;
    },
    unitOptions: function() {
      let key = this.selectedProjectId + '-' + this.selectedTypology + '-' + this.selectedType;
      return this.selectedType ? this.unitOptionsMap.get(key) : [{ text: 'No disponibles', value: 'No disponibles' }];
    },
    showUnit: function() {
      return this.selectedUnit.unitNumber ? true : false;
    },

    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
           //this.$validator.reset()
           //this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.dataName;
    }
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.unitNumber = ""
        this.amount = null
        //this.currency = ""
        this.description = ""
        this.status = ""
        this.type= ""
    },
    submitData() {
      //console.log("submitData");
      var unit = "";
      if (this.selectedUnit!="") {
        unit = this.selectedUnit;
      } else {
        unit = this.unitNumber;
      }
      var projectIdPost = "";
      if (this.selectedProjectId!="") {
        projectIdPost = this.selectedProjectId;
      } else {
        projectIdPost = this.dataprojectId;
      }
      var clientIdPost = "";
      if (this.selectedClientId!="") {
        clientIdPost = this.selectedClientId;
      } else {
        clientIdPost = this.dataclientId;
      }
      //this.$validator.validateAll().then(result => {
          //if (this.dataId) {
            const obj = {
              id: this.id,
              clientIdentifier: clientIdPost,
              projectId: projectIdPost,
              unitNumber: unit.unitNumber,
              amount: this.amount,
              currency: 'UF',
              //floor: this.floor,
              scope: 'ONE_CLIENT_ONE_USE',
              status: this.datastatus,
              //typology : this.typology,
              type: this.datatype,
              //totalSize: this.totalSize,
              //terraceSize: this.terraceSize,
              //interiorSize: this.interiorSize,
              //rooms: this.rooms,
              //bathrooms: this.bathrooms,
              //orientation: this.orientation
            }
            //console.log("obj");
            //console.log(obj);
            if (obj && 
                obj.projectId && 
                obj.clientIdentifier && 
                obj.amount && 
                obj.scope && 
                obj.status && 
                obj.type) {

            

              if(this.id != null && this.id != '') {
                //console.log("update discount");
                //console.log(this.id);
                this.$store.dispatch("dataListDiscount/updateDiscount", obj).then(() => { /*.catch(err => { console.error(err) })*/
                    //console.info('unidad actualizada');
                    this.$vs.notify({
                          title:'Actualizado',
                          text:'La unidad ha sido actualizada y se verá reflejado al refrescar',
                          color:'primary'});
                  })
              } else {
                //console.log("new unit");
                this.$store.dispatch("dataListDiscount/newDiscount", obj).then(() => { /*.catch(err => { console.error(err) })*/
                    //console.info('unidad guardada');
                    this.$vs.notify({
                          title:'Guardado',
                          text:'La unidad ha sido guardada y se verá reflejado al refrescar',
                          color:'primary'});
                  })                  
              }
              this.isSidebarActiveLocal = false;
            } else {
                this.$vs.notify({
                  title:'Error',
                  text:'Ha ocurrido un problema guardando el registro',
                  color:'danger'});
            }

            //this.$emit('closeSidebar')
            //this.initValues()
          //}
      //})
    },
    deleteData() {
      const obj = {
        id: this.id,
        projectId: this.projectId,
        unitNumber: this.unitNumber,
      }
      if(this.id != null) {
        this.$store.dispatch("dataListDiscount/removeDiscount", obj)//.catch(err => { console.error(err) })
      } 
      this.$emit('closeSidebar')
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    // metodos proyecto
    filterMinMaxValueRange () {
      if (this.value1 < this.filterMin || this.value1 >this.filterMax || this.value2 < this.filterMin || this.value2 >this.filterMax ) {
        this.value1 = this.filterMin;
        this.value2 = this.filterMax;
        this.changeRange([this.value1,this.value2]);
      }
    },
    selectTypology() {
      if (this.selectedType) this.selectedType = '';
      if (this.selectedUnit) this.selectedUnit = '';
    },
    selectType() {
      if (this.selectedUnit) this.selectedUnit = '';
    },

    listActiveProjectsByCompany: function() {
      RealEstateProjectService.listProjects(0, 10)
        .then(response => {
          if (response) {
            this.projectData = response.data;
            //this.$store.dispatch('setRealEstateProjects', response.data);
            this.createOptionsMaps(response.data);
          }
        })
        .catch(error => {
          this.$log.error('Error calling RealEstateProjectService.listActiveProjectsByCompany :  - ', error);
        });

    },
    listClients: function() {
      ClientService.listClient(0, 1000)
        .then(response => {
          if (response) {
            this.clientData = response.data;
            //this.$store.dispatch('setRealEstateProjects', response.data);
            this.createClientOptionsMap(response.data);
          }
        })
        .catch(error => {
          this.$log.error('Error calling ClientService.listClient :  - ', error);
        });

    },
    createClientOptionsMap: function(clients) {
      //console.log(clients);
      for (let i = 0; i < clients.length; i++) {
        var clientName = clients[i].name+' '+clients[i].lastName;
        this.clientOptions.push({ text: clientName, value: clients[i].identifier });
      }
      //console.log(this.clientOptions);
    },
    createOptionsMaps: function(projects) {
      // Itera sobre cada proyecto
      for (let i = 0; i < projects.length; i++) {
        // Asigna el proyecto actual a la variable project
        let project = projects[i];

        // Para el proyecto actual crea un nuevo arreglo de tipologías
        //let innerTypologyOptionsMap = new Map();
        let innerTypologyOptions = new Array();

        // Para el proyecto actual crea un nuevo arreglo de tipo
        let innerTypeOptions = new Array();

        // Para el proyecto actual crea un nuevo arreglo de unidades
        let innerUnitOptions = new Array();


        // agrega el proyecto actual al arreglo de opciones de proyectos
        this.projectOptions.push({ text: project.name, value: project.id, quotesNumber: project.quotesNumber });

        // Obtiene las unidades del proyecto
        let units = project.units;
        let additionals = project.additionals;

        if (additionals) {
          for (let j = 0; j < additionals.length; j++) {
            let additional = additionals[j];
            if (additional.status === 'AVAILABLE') {
              // agrega un adicional
              switch (additional.type) {
                case 'parking':
                this.projectAdditional.push({text: additional.description, value: additional});
                break;
                case 'warehouse':
                this.projectAdditionalWarehouse.push({text: additional.description, value: additional});
                break;
                case 'parkingWarehouse':
                this.projectAdditional.push({text: additional.description, value: additional});
                break;
              }
            }
          }
        }
        if (units) {
          // Recorre las unidades del proyecto
          for (let j = 0; j < units.length; j++) {
            // Asigna la unidad actual a una variable
            let unit = units[j];
            let typology = unit.typology;
            let type = unit.type;
            let footUF =  unit.price * 0.15;
            let foot = footUF * this.valueUF;
            let quotesValue = foot / this.quotesNumber;

              if (quotesValue < this.filterMinUnits*1000 || this.filterMinUnits === 0) {
                this.filterMinUnits = quotesValue/1000;
              }
              if (quotesValue > this.filterMaxUnits*1000) {
                this.filterMaxUnits = quotesValue/1000;
              }

            // Aplica solo para las unidades disponibles a la venta
            //if (quotesValue > this.value1 * 1000 && quotesValue < this.value2 * 1000 && unit.status === 'AVAILABLE') {
            if (unit.status === 'AVAILABLE') {

            // Arreglo de tipologías
            if (innerTypologyOptions.filter(typologyOption => typologyOption.text === typology).length === 0) {
              innerTypologyOptions.push({ text: typology, value: typology });
            }

            // Arreglo de tipos
            let typeOptionsMapKey = project.id + '-' + typology;
            innerTypeOptions = this.typeOptionsMap.get(typeOptionsMapKey);
            if (!innerTypeOptions) {
              innerTypeOptions = new Array();
            }
            if (innerTypeOptions.filter(typeOption => typeOption.text === type).length === 0) {
              innerTypeOptions.push({ text: type, value: type });
              this.typeOptionsMap.set(typeOptionsMapKey, innerTypeOptions);
            }
            // if (unit.status === 'AVAILABLE') {

              // agrega una unidad
              let unitOptionsMapKey = project.id + '-' + typology + '-' + type;
              //let unitOptionsMapKey = project.id + '-' + type;
              innerUnitOptions = this.unitOptionsMap.get(unitOptionsMapKey);
              if (!innerUnitOptions) {
                innerUnitOptions = new Array();
              }
              innerUnitOptions.push({ text: unit.unitNumber, value: unit });
              this.unitOptionsMap.set(unitOptionsMapKey, innerUnitOptions);
            }
            this.typologyOptionsMap.set(project.id, innerTypologyOptions);
          }
            if (this.filterMinUnits != 0 && this.filterMaxUnits != 0 && this.filterMinUnits) {
              this.filterMin=Math.round(this.filterMinUnits)-10;
              this.filterMax=Math.round(this.filterMaxUnits)+10;
            }
            this.filterMinMaxValueRange();
        }
      }
    },
  },
  components: {
    VuePerfectScrollbar,
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
