<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <discount-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="discounts">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        </div>
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ discounts.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : discounts.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=5">
              <span>5</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <vs-th sort-key="project">Proyecto</vs-th>
        <vs-th sort-key="discountUnit">Unidad</vs-th>
        <vs-th sort-key="client">Cliente</vs-th>
        <vs-th sort-key="type">Tipo</vs-th>
        <vs-th sort-key="status">Validez</vs-th>
        <vs-th sort-key="amount">Monto</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th>Nuevo 
          <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="addNewData()" />
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.projectName }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.unitNumber }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.clientName }} </p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ translateType(tr.type) }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ translateScope(tr.scope) }}
                <br>
                <vs-chip :color="getDaysStatusColor(daysDifference(tr.endDate))" class="product-order-status">{{daysDifference(tr.endDate)}} dias de vigencia</vs-chip>

                </p>
            </vs-td>
            <vs-td>
              <p class="product-category" v-if="tr.type === 'FIXED_AMOUNT'">{{ tr.amount | numeral('0, 0.00') }}</p>
              <p class="product-category" v-if="tr.type === 'PERCENTAGE'">{{ tr.amount }}%</p>
            </vs-td>
            <vs-td>
              <vs-chip :color="getOrderStatusColor(tr.status)" class="product-order-status">{{translateStatus(tr.status)}}</vs-chip>
              <div v-if="tr.status === 'PAID'">
                <vs-chip :color="getDaysStatusColor(daysDifference(tr.lastUpdate))" class="product-order-status">{{daysDifference(tr.lastUpdate)}} dias de vigencia</vs-chip>                
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div>
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />Gestionar</div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import DiscountViewSidebar from '@/views/ui-elements/data-list/DiscountViewSidebar.vue'
import moduleDiscount from "@/store/discount/moduleDiscount.js"
import Vue from 'vue';
import { rutFilter } from 'vue-dni';

import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, { locale: 'es' });

Vue.filter('rutFilter', rutFilter);
export default {
  components: {
    DiscountViewSidebar
  },
  data() {
    return {
      selected: [],
      // products: [],
      itemsPerPage: 5,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    discounts() {
      //console.log(this.$store.state.dataListDiscount.discounts);
      return this.$store.state.dataListDiscount.discounts;
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.discounts.length
    }
  },
  methods: {
    getDaysStatusColor(days) {
      if (days > 3) return "success"
      if (days < 4) return "danger"
      return "primary"
    },
    daysDifference (date) {
      var date1 = new Date(date);  
      var date2 = new Date();
      // The number of milliseconds in one day
      var ONEDAY = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);
      // Convert back to days and return
      var value = 15-Math.round(difference_ms/ONEDAY);
      if (value < 0) {
        value = 0;
      }
      return value;
    },
    daysDifferenceDouble (dateA,dateB) {
      var date1 = new Date(dateA);  
      var date2 = new Date(dateB);
      // The number of milliseconds in one day
      var ONEDAY = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);
      // Convert back to days and return
      var value = 15-Math.round(difference_ms/ONEDAY);
      if (value < 0) {
        value = 0;
      }
      return value;
    },
    getOrderStatusColor(status) {
      if (status == 'NEW') return "primary"
      if (status == 'USED') return "danger"
      if (status == 'FINISHED') return "success"
      return "primary"
    },
    translateStatus(status) {
      if (status == 'NEW') return "Nuevo"
      if (status == 'USED') return "Usado"
      if (status == 'FINISHED') return "Terminada"
      return "Indeterminado"
    },
    translateScope(status) {
      if (status == 'ONE_CLIENT_ONE_USE') return "Una vez"
      return "Indeterminado"
    },
    translateType(status) {
      if (status == 'PERCENTAGE') return "Porcentaje"
      if (status == 'FIXED_AMOUNT') return "En Cantidad"
      return "Indeterminado"
    },
    translatePaymentForm(paymentForm) {
      if (paymentForm == 'NA') return "NA"
      if (paymentForm == 'CHECK') return "Cheque"
      if (paymentForm == 'TC') return "Tarjeta de Crédito"
      if (paymentForm == 'ONECLICK') return "OneClick"
      return "Indeterminado"
    },
    translatePaymentAlternative(paymentAlternative) {
      if (paymentAlternative == 'INSTALLMENTS') return "Cuotas Iguales"
      if (paymentAlternative == 'INSTALLMENTS_BIG_LAST_INSTALLMENT') return "Cuoton mas cuotas"
      return "Indeterminado"
    },
    showDiscount(discountId) {
      localStorage.setItem("discountId", discountId);
      this.$router.push('/clientPanel/discountview/');
    },
    checkPending(bookingStatus) {
      if (bookingStatus=="PENDING" || bookingStatus=="APPROVED") {
        return true;
      }
      return false;
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData(id) {
      this.$store.dispatch("dataListBooking/removeItem", id)//.catch(err => { console.error(err) })
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      //console.log(data);
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    }
  },
  created() {
    if (!moduleDiscount.isRegistered) {
      this.$store.registerModule('dataListDiscount', moduleDiscount)
      moduleDiscount.isRegistered = true
    }
    let pageObject = {
      page: 0,
      size: 100
    };

    this.$store.dispatch("dataListDiscount/listDiscounts", pageObject)
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;
        .vs-table--search-input {
          width: 100%;
        }
      }
    }
    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }
    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;
        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      >span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search {
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          &+i {
            left: 1rem;
          }
          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }
    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;
        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }
    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
